import {
  Button,
  Drawer,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Spin,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { filter, isEmpty, map } from "lodash";
import forEach from "lodash.foreach";
import get from "lodash.get";
import React, { useEffect, useState } from "react";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import addPlacementDynamicCap from "../../../newApi/pairing/addPlacementDynamicCap.js";
import getPackages from "../../../newApi/pairing/getPackages";
import getPairingUser from "../../../newApi/pairing/getPairingUser";
import getTopUp from "../../../newApi/pairing/getTopUp";
import getPlacements from "../../../newApi/placement/getPlacements";
import createTopUp from "../../../newApi/topup/createTopUp";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";

// markup
const inputColor = "grey";
const TopUpDrawer = (props) => {
  // const location = useLocation();
  const { t, languages } = useTranslation();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [packagesList, setPackagesList] = useState([]);
  // const [amount, setAmount] = useState();
  const [visible, setVisible] = useState(false);
  const [isFirstTopUp, setIsFirstTopUp] = useState(false);
  // const [sponsorUser, setSponsorUser] = useState({});
  const accessKey = get(props, "accessKey");
  const user = get(props, "user");
  const pairingUser = get(props, "pairingUser");

  // console.log("props user", user);
  // console.log("l here", languages);

  useEffect(() => {}, []);

  useEffect(() => {
    setVisible(props.visible === true);
    if (props.visible === true) {
      if (!isEmpty(pairingUser)) {
        if (get(pairingUser, "wallet")) {
          if (get(pairingUser, "wallet") < 100) {
            // console.log(" here not wallet");
            props.triggerNotification("appBalance");
          }
        } else {
          props.triggerNotification("appBalance");
        }
      }
    }
  }, [props.visible]);

  useEffect(() => {
    if (!isEmpty(props.data)) {
      // console.log("data", props.data);
      let selected_data = props.data;
      getPairingUser(
        1,
        0,
        {
          _id: get(selected_data, "userId"),
          parentCheck : true,
        },
        accessKey
      )
        .then((res) => {
          let userData = get(res, "data");
          userData = userData[0];
          // console.log("user", userData);
          let sponsorInfo = get(userData, "populatedSponsor") || {};
          // setSponsorUser(sponsorInfo);
          form.setFieldsValue({
            username: get(selected_data, "username"),
            name: get(selected_data, "username"),
            sponsor: get(sponsorInfo, "username") || "-",
            placement: get(selected_data, "username"),
          });
        })
        .catch((err) => {
          console.log(err);
        });

      getTopUp(
        "all",
        0,
        {
          userId: get(selected_data, "userId"),
          amountNe: 0,
        },
        accessKey
      )
        .then((res) => {
          // console.log("tp", res);
          let data = get(res, "data");
          let length = data.length;
          if (length === 0) {
            setIsFirstTopUp(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [props.data]);

  useEffect(() => {
    getPackages("all", 0, {
      status: 0,
    })
      .then((res) => {
        // console.log("res", res);
        let data = get(res, "data");
        if (!isEmpty(data)) {
          setPackagesList(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    // getPlacements();
  }, []);

  function submitRegister() {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        // console.log("values", values);
        let selectedData = get(props, "data");
        // console.log("selectedData", selectedData);

        let correctValues = {
          placementId: get(selectedData, "_id"),
          userId: get(selectedData, "userId"),
          // buyerId: get(user, "pairingUser._id"),
          username: get(selectedData, "username"),
          packageId: get(values, "package"),
          amount: get(values, "amount"),
          createdBy: get(user, "pairingUser._id"),
        };
        // console.log(correctValues);

        createTopUp(correctValues)
          .then((res) => {
            // console.log("topupS", res);
            let topupData = get(res, "data");

            if (isFirstTopUp === true) {
              addPlacementDynamicCap(
                {
                  placementId: get(selectedData, "_id"),
                  amount: 250,
                  clientId: get(topupData, "_id"),
                  remark: "Free 250",
                },
                accessKey
              )
                .then((res2) => {
                  // console.log("addplacement", res2);
                  message.success(t("topUpSuccess", sourceKey.pairing));
                  setLoading(false);
                  form.resetFields();
                  if (props.onSuccess) {
                    props.onSuccess();
                  }
                  close();
                })
                .catch((err) => {
                  setLoading(false);
                  console.log(err);
                });
            } else {
              message.success(t("topUpSuccess", sourceKey.pairing));
              setLoading(false);
              form.resetFields();
              if (props.onSuccess) {
                props.onSuccess();
              }
              close();
            }
          })
          .catch((err) => {
            setLoading(false);
            // message.error(err?.message);
            message.error(t(err?.errorCode, sourceKey.pairing));

            console.log(err);
          });
      })
      .catch((err) => {
        forEach(get(err, "errorFields"), (item) => {
          message.error(get(item, "errors[0]"));
        });
        setLoading(false);
      });
  }

  function close() {
    setVisible(false);
    setIsFirstTopUp(false);
    if (props.onClose) {
      props.onClose();
    }
  }

  return (
    <React.Fragment>
      <Drawer
        visible={visible}
        closable={false}
        // onClose={close}
        width="100%"
        className="body-no-padding"
      >
        <Layout>
          <Spin spinning={loading}>
            <NavHeader
              suffix={
                <span className="inline-block text-base text-right  font-semibold">
                  {/* 升级{" "}
                  <MediaQuery maxWidth={440}>
                    <br />
                  </MediaQuery> */}
                  {t("topUp", sourceKey.pairing)}
                </span>
              }
              showBack={true}
              onBack={() => {
                close();
              }}
            >
              <div className="mt-4">
                <div className=" px-5">
                  <div className="">
                    <Form
                      form={form}
                      autoComplete="off"
                      style={{ color: "black" }}
                    >
                      <div className="grid grid-cols-4 gap-1">
                        <div className="col-span-2">
                          <div className="">
                            {t("username", sourceKey.pairing)} :
                          </div>
                          <div className="my-3">
                            <Form.Item
                              name="username"
                              initialValue=""
                              style={{ margin: 0 }}
                              rules={[
                                {
                                  required: true,
                                  message: (
                                    <React.Fragment>
                                      {/* 请填写用戶名字
                                      <br /> */}
                                      {t("usernameRequired", sourceKey.pairing)}
                                    </React.Fragment>
                                  ),
                                },
                              ]}
                            >
                              <Input
                                placeholder={t(
                                  "usernameRequired",
                                  sourceKey.pairing
                                )}
                                autoComplete="off"
                                style={{ color: "grey" }}
                                disabled
                                className={"input-border"}
                              />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="col-span-2">
                          <div className="">
                            {t("name", sourceKey.pairing)} :
                          </div>
                          <div className="my-3">
                            <Form.Item
                              name="name"
                              style={{ margin: 0 }}
                              initialValue=""
                              rules={[
                                {
                                  required: true,
                                  message: (
                                    <React.Fragment>
                                      {/* 请填写名字
                                      <br /> */}
                                      {t("nameRequired", sourceKey.pairing)}
                                    </React.Fragment>
                                  ),
                                },
                              ]}
                            >
                              <Input
                                placeholder={t(
                                  "nameRequired",
                                  sourceKey.pairing
                                )}
                                disabled
                                style={{ color: "grey" }}
                                autoComplete="off"
                                className={"input-border"}
                              />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="col-span-2">
                          <div className="">
                            {" "}
                            {t("sponsorUsername", sourceKey.pairing)}:
                          </div>
                          <div className="my-3">
                            <Form.Item
                              name="sponsor"
                              style={{ margin: 0 }}
                              initialValue=""
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: (
                              //       <React.Fragment>
                              //         请填写推荐用戶
                              //         <br />
                              //         Sponsor user is required.
                              //       </React.Fragment>
                              //     ),
                              //   },
                              // ]}
                            >
                              <Input
                                placeholder="Sponsor Username"
                                autoComplete="off"
                                style={{ color: inputColor }}
                                className={"input-border"}
                                disabled
                              />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="col-span-2">
                          <div className="">
                            {t("placement", sourceKey.pairing)}:
                          </div>
                          <div className="rounded-xl my-3">
                            <Form.Item
                              name="placement"
                              style={{ margin: 0 }}
                              rules={[
                                {
                                  required: true,
                                  message: (
                                    <React.Fragment>
                                      {/* 请填写节点安置 <br /> */}
                                      {t(
                                        "placementRequired",
                                        sourceKey.pairing
                                      )}
                                    </React.Fragment>
                                  ),
                                },
                              ]}
                            >
                              <Input
                                placeholder="Placement"
                                autoComplete="off"
                                style={{ color: inputColor }}
                                className={"input-border"}
                                disabled
                              />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="col-span-4">
                          <div className="">
                            {" "}
                            {t("package", sourceKey.pairing)}:
                          </div>
                          <div className="rounded-3xl my-3">
                            <Form.Item
                              className="my-select-container"
                              name="package"
                              style={{ margin: 0 }}
                              rules={[
                                {
                                  required: true,
                                  message: (
                                    <React.Fragment>
                                      {/* 请选择配套 <br /> */}
                                      {t("selectPackage", sourceKey.pairing)}
                                    </React.Fragment>
                                  ),
                                },
                              ]}
                            >
                              <Select
                                // showSearch
                                style={{ width: "100%" }}
                                placeholder={t(
                                  "selectPackage",
                                  sourceKey.pairing
                                )}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                onSelect={(values) => {
                                  let package_data = filter(
                                    packagesList,
                                    (item) => {
                                      return get(item, "_id") === values;
                                    }
                                  );

                                  if (!isEmpty(package_data)) {
                                    form.setFieldsValue({
                                      amount: get(package_data[0], "amount"),
                                    });
                                  }
                                }}
                              >
                                {map(packagesList, (item, index) => {
                                  let description = get(item, "description");
                                  // let showDesc ="";
                                  // if(!isEmpty(description)){
                                  //   let selectedLanguage = get(description,languages);
                                  //   if(!isEmpty(selectedLanguage))
                                  // }
                                  return (
                                    <Select.Option
                                      value={get(item, "_id") || ""}
                                      title={get(item, "amount")}
                                      key={`option-${index}`}
                                    >
                                      <div>
                                        {get(item, "name")} -{" "}
                                        {get(item, "amount")}
                                      </div>
                                      <div className="text-xs">
                                        {get(item, "description")
                                          ? get(description, languages)
                                            ? `${get(description, languages)}`
                                            : ""
                                          : ""}
                                      </div>
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </div>
                        </div>

                        <div className="col-span-4">
                          <div className="">
                            {t("amount", sourceKey.pairing)}:
                          </div>
                          <div className="rounded-3xl my-3">
                            <Form.Item
                              name="amount"
                              style={{ margin: 0 }}
                              rules={[
                                {
                                  required: true,
                                  message: (
                                    <React.Fragment>
                                      {/* 请选择配套 <br /> */}
                                      {t("selectPackage", sourceKey.pairing)}
                                    </React.Fragment>
                                  ),
                                },
                              ]}
                            >
                              <InputNumber
                                placeholder={t(
                                  "selectPackage",
                                  sourceKey.pairing
                                )}
                                className="w-full input-border"
                                style={{
                                  width: "100%",
                                  height: "30px",
                                  color: inputColor,
                                }}
                                disabled
                              ></InputNumber>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>

                  <div className="flex justify-center items-center mt-5">
                    <Button
                      className="main-button-color rounded-4xl"
                      block
                      // shape="round"
                      onClick={(e) => {
                        submitRegister();
                      }}
                    >
                      {t("topUp", sourceKey.pairing)}
                    </Button>
                  </div>
                </div>
              </div>
            </NavHeader>
          </Spin>
        </Layout>
      </Drawer>
    </React.Fragment>
  );
};

export default TopUpDrawer;
