import {
  ArrowLeftOutlined,
  DownOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { useLocation } from "@reach/router";
import { Button, Col, Input, message, Modal, Row, Spin, Tooltip } from "antd";
import { navigate } from "gatsby-link";
import { toLower } from "lodash";
import { trim } from "lodash";
import { get, isEmpty, map, orderBy } from "lodash";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getDownlineUser from "../../../newApi/pairing/getDownlineUser";
import getPairingUser from "../../../newApi/pairing/getPairingUser";
import getPlacements from "../../../newApi/placement/getPlacements";
import { triggerModal } from "../../../redux/actions/app-actions";
import { updateActiveId } from "../../../redux/actions/product-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { routes } from "../../../route";
import { formatDate } from "../../../utilities/common-function";
import { rankingImage } from "../../../utilities/pairing";
import Layout from "../../general/components/Layout";
import RequireAuthWrapper from "../../general/components/RequireAuthWrapper";
import TopUpDrawer from "../components/TopUpDrawer";

//let displayRobot = [];

const leftColor = "white";
const rightColor = "white";
// const leftColor = "#43e67c";
// const rightColor = "#fcd535";
// markup
const NodeTeamPage = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  // const [form] = useForm();
  const [loading, setLoading] = useState(false);
  // const [arrayLoading, setArrayLoading] = useState(true);
  const [data, setData] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [childInfo, setChildInfo] = useState({});
  // const [sponsorName, setSponsorName] = useState("");
  const [childVisible, setChildVisible] = useState(false);
  const [pairingUser, setPairingUser] = useState({});
  const [registerDisable, setRegisterDisable] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  let accessKey = get(props.user, "accessKey");
  const user = get(props, "user.user");
  // console.log(user);
  //let profile = get(props.user.user, "profile");
  // console.log(user);

  useEffect(() => {
    getData();
  }, [activeId]);

  useEffect(() => {
    let query = queryString.parse(location.search);
    // console.log("query", query);
    if (!isEmpty(query)) {
      if (get(query, "userId")) {

        setActiveId(get(query, "userId"));
      } else {
        setActiveId(get(user, "pairingUser._id"));
      }
    } else {
      setActiveId(get(user, "pairingUser._id"));
    }
    getPairingUserData();
  }, []);

  function getPairingUserData() {
    getPairingUser(
      1,
      0,
      {
        // _id: get(user, "pairingUser._id"),
      },
      accessKey
    )
      .then((res) => {
        // console.log(res);
        let data = get(res, "data");
        data = data[0];
        setPairingUser(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getData(activeKey) {
    if (!isEmpty(activeId)) {
      // console.log("activeId", activeId);
      setLoading(true);
      getPlacements(7, 0, {
        userId: activeId,
        // childrenId : activeId, 
        $limit: 7,
        nodeTeam: true
      })
        .then((res) => {
          // console.log(res);
          let data = orderBy(res.data, ["username"]);
          // setSponsorName(get(firstData, "sponsor"));
          let firstPlacement = data[0];
          if (get(firstPlacement, "activated") !== 1) {
            setRegisterDisable(true);
            // console.log("set true");
          } else {
            // console.log("set false");
            setRegisterDisable(false);
          }

          setData(data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }

  function registerNewUser(item, toPosition) {
    // console.log(item);
    let correctValues = {
      sponsor: get(user, "username"),
      placement: get(item, "username"),
      position: toPosition,
    };
    navigate(routes.register.to(correctValues));
  }

  // function getChildInfo(child) {
  //   if (!isEmpty(child)) {
  //     getPlacements(1, 0, {
  //       _id: get(child, "_id"),
  //       $limit: 1,
  //     })
  //       .then((res) => {
  //         // console.log(res);
  //         let childData = get(res, "data[0]");
  //         if (!isEmpty(childData)) {
  //           setChildInfo(childData);
  //           setChildVisible(true);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         // setLoading(false);
  //       });
  //   }
  // }

  const CardItem = (data) => {
    const {
      rowNo,
      username,
      amountR,
      amountL,
      index,
      childL,
      childR,
      parent,
      _id,
    } = data;

    function handleUsername(username) {
      if (username.length > 8) {
        return username.replace(
          username.substring(2, username.length - 4),
          "**"
        );
      } else {
        return username;
      }
    }

    let hideUsername = handleUsername(username);
    let leftNotAllowedRegister = index !== 3 && registerDisable === true;
    let rightNotAllowedRegister = false;
    if (index !== 3 && registerDisable === true) {
      rightNotAllowedRegister = true;
    } else {
      if (index === 3 && registerDisable === true) {
        rightNotAllowedRegister = true;
      } else if (index === 3 && registerDisable === false) {
        rightNotAllowedRegister = false;
      }
    }

    return (
      <div>
        <div
          key={"row" + index}
          className="lightgreybox-bg-color py-1"
          style={{
            // boxShadow: "0px 1px 10px rgba(254, 249, 252, 0.8)",
            borderRadius: "25px",
            cursor: "pointer",
          }}
          onClick={() => {
            setDrawerVisible(true);
            setSelectedData(data);
          }}
        >
          <Row justify="start">
            <Col style={{ textAlign: "" }} span={24}>
              <div
                className="flex justify-center items-center font-semibold"
                style={{ fontSize: "14px", fontWeight: 600 }}
              >
                <Tooltip title={username}>{hideUsername}</Tooltip>
              </div>
              <div className="flex justify-center" style={{ textAlign: "" }}>
                <div className="justify-center items-center flex mb-1">
                  <img
                    src={rankingImage(get(data, "ranking"))}
                    style={{ width: "58px", height: "38.16px" }}
                  />
                </div>
              </div>
            </Col>
          </Row>

          {index === 0 ? (
            <Row justify="start">
              <Col style={{ textAlign: "" }} span={24}>
                <div className="flex justify-center text-xs items-center ">
                  {}
                  {formatDate(get(data, "createdAt"), null)}
                </div>
              </Col>
            </Row>
          ) : (
            <Row justify="start">
              <Col style={{ textAlign: "" }} span={24}>
                <div className="flex justify-center text-xs items-center ">
                  {" "}
                  <span
                    className={`${get(data, "topUp.createdAt") ? "" : "py-2"}`}
                  >
                    {" "}
                    {get(data, "topUp.createdAt")
                      ? formatDate(get(data, "topUp.createdAt"), null)
                      : ""}
                  </span>
                </div>
              </Col>
            </Row>
          )}

          <Row gutter={4} justify="center">
            {rowNo === 3 ? (
              <>
                <MediaQuery maxWidth={460}>
                  <Col span={8}>
                    <h5 style={{ textAlign: "center", color: leftColor }}>
                      {t("l", sourceKey.pairing)}
                    </h5>
                    <h5 style={{ textAlign: "center", color: rightColor }}>
                      {t("r", sourceKey.pairing)}
                    </h5>
                  </Col>
                </MediaQuery>
                <MediaQuery minWidth={461}>
                  <Col span={12}>
                    <h5 style={{ textAlign: "center", color: leftColor }}>
                      {t("l", sourceKey.pairing)}
                    </h5>
                    <h5 style={{ textAlign: "center", color: rightColor }}>
                      {t("r", sourceKey.pairing)}
                    </h5>
                  </Col>
                </MediaQuery>
              </>
            ) : (
              <>
                <Col span={12}>
                  <h5 style={{ textAlign: "center", color: leftColor }}>
                    {t("left", sourceKey.pairing)}
                  </h5>
                  <h5 style={{ textAlign: "center", color: leftColor }}>
                    {amountL}
                  </h5>
                </Col>
              </>
            )}
            <br />
            {rowNo === 3 ? (
              <>
                <MediaQuery maxWidth={460}>
                  <Col span={16}>
                    <h5 style={{ textAlign: "center", color: leftColor }}>
                      {amountL}
                    </h5>
                    <h5 style={{ textAlign: "center", color: rightColor }}>
                      {amountR}
                    </h5>
                  </Col>
                </MediaQuery>
                <MediaQuery minWidth={461}>
                  <Col span={12}>
                    <h5 style={{ textAlign: "center", color: leftColor }}>
                      {amountL}
                    </h5>
                    <h5 style={{ textAlign: "center", color: rightColor }}>
                      {amountR}
                    </h5>
                  </Col>
                </MediaQuery>
              </>
            ) : (
              <>
                <Col span={12}>
                  <h5 style={{ textAlign: "center", color: rightColor }}>
                    {t("right", sourceKey.pairing)}
                  </h5>
                  <h5 style={{ textAlign: "center", color: rightColor }}>
                    {amountR}
                  </h5>
                </Col>
              </>
            )}
          </Row>
        </div>
        <div className=" pl-2">
          <Row gutter={4} justify="center">
            <Col span={12}>
              {rowNo === 3 ? (
                childL && childL?.position === "L" ? (
                  <div
                    className="border-2 flex justify-center pr-2 pt-2 mt-8 pb-2"
                    style={{
                      borderRadius: "4px",
                      width: "40px",
                      height: "31px",
                      borderColor: "#10b349",
                      backgroundColor: "#43e67c",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setActiveId(childL?.userId);
                    }}
                  >
                    <Tooltip title={(childL?.username)} placement="bottom">
                      <DownOutlined
                        style={{
                          color: "black",
                          width: "9.25px",
                          height: "5.31px",
                        }}
                      />
                    </Tooltip>
                  </div>
                ) : (
                  <div
                    className="border-2 flex justify-center pr-2 pt-2 mt-8 pb-2"
                    style={{
                      backgroundColor: "#272727",
                      borderRadius: "4px",
                      width: "40px",
                      height: "31px",
                      borderColor: `${
                        leftNotAllowedRegister === true ? "#736969" : "#FCB233"
                      }`,
                      cursor: `${
                        leftNotAllowedRegister === true ? "" : "pointer"
                      }`,
                    }}
                    onClick={() => {
                      if (leftNotAllowedRegister === false) {
                        registerNewUser(data, "L");
                      }
                    }}
                  >
                    {leftNotAllowedRegister ? (
                      <UserAddOutlined
                        style={{
                          color: "#736969",
                          width: "3.9px",
                          height: "11.62px",
                          fontSize: 14,
                        }}
                      />
                      ) : (
                      <Tooltip title="Register new user" placement="bottom">
                        <UserAddOutlined
                        style={{
                          color: "#FCB233",
                          width: "3.9px",
                          height: "11.62px",
                          fontSize: 14,
                        }}
                      />
                      </Tooltip>
                    )}
                  </div>
                )
              ) : null}
            </Col>
            <Col span={12}>
              {rowNo === 3 ? (
                childR && childR?.position === "R" ? (
                  <div
                    className="border-2 flex justify-center bg-main-color-gradient pr-2 pt-2 mt-8 pb-2"
                    style={{
                      borderRadius: "4px",
                      width: "40px",
                      height: "31px",
                      borderColor: "#FCB233",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setActiveId(childR?.userId);
                      // getChildInfo(childR?.userId);
                    }}
                  >
                    <Tooltip title={(childR?.username)} placement="bottom">
                      <DownOutlined
                        style={{
                          color: "black",
                          width: "9.25px",
                          height: "5.31px",
                        }}
                      />
                    </Tooltip>
                    
                  </div>
                ) : (
                  <div
                    className="border-2 flex justify-center pr-2 pt-2 mt-8 pb-2"
                    style={{
                      backgroundColor: "#272727",
                      borderRadius: "4px",
                      width: "40px",
                      height: "31px",
                      borderColor: `${
                        rightNotAllowedRegister === true ? "#736969" : "#FCB233"
                      }`,
                      cursor: `${
                        rightNotAllowedRegister === true ? "" : "pointer"
                      }`,
                    }}
                    onClick={() => {
                      if (rightNotAllowedRegister === false) {
                        registerNewUser(data, "R");
                      }
                    }}
                  >
                    {rightNotAllowedRegister ? (
                      <UserAddOutlined
                        style={{
                          color: "#736969",
                          width: "3.9px",
                          height: "11.62px",
                          fontSize: 14,
                        }}
                      />
                      ) : (
                      <Tooltip title="Register new user" placement="bottom">
                        <UserAddOutlined
                        style={{
                          color: "#FCB233",
                          width: "3.9px",
                          height: "11.62px",
                          fontSize: 14,
                        }}
                      />
                      </Tooltip>
                    )}
                  </div>
                )
              ) : null}
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  const _renderContent = () => {
    return (
      <div className="grid grid-cols-8 gap-2 mx-2 pt-1 unselectable">
        {data.map((i, index) => {
          if (index === 0) {
            return (
              <div className="col-span-8 ">
                {CardItem({ ...i, index, rowNo: 1 })}
              </div>
            );
          }
        })}

        {data.map((i, index) => {
          if (index > 0 && index <= 2) {
            return (
              <div className="col-span-4 ">
                {CardItem({ ...i, index, rowNo: 2 })}
              </div>
            );
          }
        })}

        {data.map((i, index) => {
          if (index > 2) {
            return (
              <div className="col-span-2 ">
                {CardItem({ ...i, index, rowNo: 3 })}
              </div>
            );
          }
        })}
      </div>
    );
  };

  let buttonActions = [
    {
      text: t("levelUp", sourceKey.pairing),
      textCn: "上一层",
      onClick: () => {
        if (!isEmpty(data)) {
          let firstData = data[0];
          let parent = get(firstData, "parent");
          if (
            !isEmpty(parent) &&
            activeId !== get(user, "profile.pairingUserId")
          ) {
            setActiveId(parent.userId);
          } else {
            // message.info("You are on the top");
            message.info(t("onTop", sourceKey.pairing));
          }
        }
      },
    },
    // {
    //   text : "1 Level Down",
    //   textCn : "下一层",
    //   onClick : (item)=>{

    //   }
    // },
    {
      text: t("toTop", sourceKey.pairing),
      textCn: "置顶",
      onClick: (item) => {
        setActiveId(get(user, "pairingUser._id"));
      },
    },
  ];

  function searchName(e) {
    let searchValue = e.target.value;
    setSearchValue(trim(toLower(searchValue)));
    if (!isEmpty(searchValue)) {
      getDownlineUser(
        1,
        0,
        {
          requesterId: get(user, "pairingUser._id"),
          username: trim(toLower(searchValue)),
        },
        accessKey
      )
        .then((res) => {
          let data_res = get(res, "data");
          if (!isEmpty(data_res)) {
            setActiveId(get(data_res, "_id"));
          }
          message.success("Success");
        })
        .catch((err) => {
          // console.log(err);
          // message.error(err?.message);
        });
    } else {
      setActiveId(get(user, "pairingUser._id"));
    }
  }

  function triggerNotification(type) {
    if (type === "appBalance") {
      props.triggerModal({
        type: "appBalance",
        redirection: routes.deposit.to(),
      });
    }
  }

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          <RequireAuthWrapper>
            <div className="flex justify-start items-center px-2 pt-2 ">
              <div
                className="flex items-center arrowbox-bg-color p-2  rounded-full  cursor-pointer"
                onClick={(e) => {
                  navigate(routes.home.to());
                }}
              >
                <ArrowLeftOutlined className="" style={{ fontSize: 20 }} />
              </div>
              <div className="p-2 text-white" style={{ width: "100%" }}>
                <Input
                  onChange={searchName}
                  onPressEnter={searchName}
                  placeholder={t("search", sourceKey.pairing)}
                  style={{
                    width: "100%",
                    borderRadius: "12px",
                    boxShadow: "0px 0px 12px rgba(111, 111, 111, 0.1)",
                    textTransform: "lowercase",
                  }}
                  value={searchValue}
                  allowClear={true}
                />
              </div>
              {/* <span className="inline-block" style={{ width: "25px" }}></span> */}
            </div>
            <div
              className=""
              // style={{ borderTopLeftRadius: 40, borderTopRightRadius: 40 }}
            >
              <div className="grid grid-cols-4 gap-1">
                {map(buttonActions, (action, index) => {
                  return (
                    <div
                      className="type-bg-color col-span-2 py-2  mx-2 px-2"
                      style={{ borderRadius: "12px", cursor: "pointer" }}
                      onClick={() => {
                        action.onClick();
                      }}
                    >
                      {/* <div className="flex justify-center">
                          {get(action, "textCn")}
                        </div> */}
                      <div className="flex justify-center">
                        {get(action, "text")}
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* <div className=" text-xl font-semibold flex justify-center">
                {sponsorName || ""}
              </div> */}
              <div className="pt-1">{_renderContent()}</div>
            </div>

            <TopUpDrawer
              visible={drawerVisible}
              onClose={() => {
                setDrawerVisible(false);
                setSelectedData({});
              }}
              onSuccess={() => {
                getData();
                getPairingUserData();
              }}
              triggerNotification={triggerNotification}
              data={selectedData}
              pairingUser={pairingUser}
              user={user}
              accessKey={accessKey}
            />
          </RequireAuthWrapper>
          <Modal
            wrapClassName="no-padding-modal-body"
            centered
            maskClosable={false}
            closable={false}
            mask
            footer={null}
            className=""
            visible={childVisible}
            //  onCancel={() => {
            //      close()
            //  }}
          >
            <div className="theme-bg-color grid place-items-center p-8 my-8">
              <div className="py-4 text-lg font-semibold">
                {get(childInfo, "username") || ""}
              </div>
              <div className="py-4">
                <img
                  src={rankingImage(get(childInfo, "ranking"))}
                  style={{ width: 45, height: 45 }}
                />
              </div>
              <div className="flex justify-center">
                <div className="m-4 text-center">
                  {t("left", sourceKey.pairing)} <br />
                  {get(childInfo, "amountL") || 0}
                </div>

                <div className="m-4 text-center">
                  {t("right", sourceKey.pairing)} <br />
                  {get(childInfo, "amountR") || 0}
                </div>
              </div>

              <div className="flex justify-center mt-8">
                <Button
                  className="second-button-color mr-8 "
                  onClick={() => {
                    setChildVisible(false);
                    setChildInfo({});
                  }}
                >
                  <div className="">{t("close")}</div>
                </Button>
                <Button
                  className="main-button-color"
                  onClick={() => {
                    setActiveId(get(childInfo, "userId"));
                    setChildVisible(false);
                    setChildInfo({});
                  }}
                >
                  <div className="">{t("proceed")}</div>
                </Button>
              </div>
            </div>
          </Modal>
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  updateActiveId,
  triggerModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(NodeTeamPage);
